import { initializeApp } from "firebase/app";
import {
  query,
  addDoc,
  getFirestore,
  collection,
  getDoc,
  doc,
  updateDoc,
  deleteDoc,
  getDocs,
  setDoc,
  arrayUnion,
  arrayRemove,
  increment,
} from "firebase/firestore";
import { getAuth, signOut } from "firebase/auth";
import {getStorage, ref, uploadBytes, getDownloadURL, listAll, getMetadata} from "firebase/storage";
import JSZip from 'jszip';
import { saveAs } from 'file-saver';

// Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: "AIzaSyDRjUb5EgnnXBcvPCIVIet6TF6dpFptDM4",
  authDomain: "bekk-rebus.firebaseapp.com",
  projectId: "bekk-rebus",
  storageBucket: "bekk-rebus.appspot.com",
  messagingSenderId: "402968973027",
  appId: "1:402968973027:web:6ea0836e7c834446227fd6"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);

export const db = getFirestore(app);

const storage = getStorage();

//Auth
export const auth = getAuth(app);

export const logOut = () => {
  signOut(auth).catch((error) => {
    console.log(error.code + ": " + error.message);
  });
};

// ---- Functions ----

async function createTeam(teamName) {
  const colRef = collection(db, "teams");
  await addDoc(colRef, { name: teamName, points: 0 });
}

async function updateTeamName(newTeamName, teamId) {
  const teamRef = doc(db, "teams", teamId);

  await updateDoc(teamRef, {
    name: newTeamName
  })
}

// Sets team as taken task
async function takeTask(taskId, teamId) {
  const taskRef = doc(db, "tasks", taskId);
  const taskSnap = await getDoc(taskRef);

  await updateDoc(taskRef, {
    takenBy: arrayUnion(teamId)
  });

  const feedDescription = `har tatt oppgaven ${taskSnap.data().title}!`
  await addFeedUpdate(feedDescription, teamId, "CLAIMED")
}

// Adds points to the team that completed the task and moves the team to completed
async function completeTask(taskId, taskValue, teamId) {
  const teamRef = doc(db, "teams", teamId);
  const taskRef = doc(db, "tasks", taskId);
  const taskSnap = await getDoc(taskRef);

  await updateDoc(taskRef, {
    takenBy: arrayRemove(teamId),
    completedBy: arrayUnion(teamId)
  });

  await updateDoc(teamRef, {
    points: increment(taskValue)
  });

  const feedDescription = `har fullført oppgaven ${taskSnap.data().title} og øker med ${taskValue} poeng!`
  await addFeedUpdate(feedDescription, teamId, "COMPLETED")
}

async function failTask(taskId, taskValue, teamId) {
  const teamRef = doc(db, "teams", teamId);
  const taskRef = doc(db, "tasks", taskId);
  const taskSnap = await getDoc(taskRef);

  await updateDoc(taskRef, {
    takenBy: arrayRemove(teamId)
  })
  await updateDoc(teamRef, {
    points: increment(Math.round(-taskValue / 2))
  });

  const feedDescription = `måtte trekke seg fra oppgaven ${taskSnap.data().title} og mister ${Math.round(taskValue / 2)} poeng!`

  await addFeedUpdate(feedDescription, teamId, "RETRACTED")
}

async function uploadMedia(file, description, teamId, name) {
  const imageRef = ref(storage, "media/" + Date.now() + "_" + file.name);

  await uploadBytes(imageRef, file);
  const url = await getDownloadURL(imageRef);

  await addFeedUpdate(description, teamId, "MEDIA", url, name)
}

async function addFeedUpdate(description, teamId, type, url = null, name = null) {
  const colRef = collection(db, "media");

  await addDoc(colRef, {
    url: url,
    description: description,
    name: name,
    teamId: teamId,
    date: Date.now(),
    type: type,
    likeCount: 0
  });
}

// ADMIN FUNCTIONS

async function likeFeedEntry(entryId) {
  const entryRef = doc(db, "media", entryId);
  const entrySnap = await getDoc(entryRef);

  const oldLike = entrySnap.data().likeCount ?? 0

  await updateDoc(entryRef,
    {
      likeCount: oldLike === 0 ? 1 : increment(1)
    }
  )
}

export const downloadAllMediaAsZipFile = async () => {
  const jszip = new JSZip();
  const storage = getStorage();
  const folderRef = ref(
      storage,
      'media'
  );
  const folder = await listAll(folderRef);
  const promises = folder.items
      .map(async (item) => {
        console.log('map item');
        const file = await getMetadata(item);
        const fileRef = ref(storage, item.fullPath);
        const fileBlob = await getDownloadURL(fileRef).then((url) => {
          return fetch(url).then((response) => response.blob());
        });
        jszip.file(file.name, fileBlob);
      })
      .reduce((acc, curr) => acc.then(() => curr), Promise.resolve());
  await promises;
  const blob = await jszip.generateAsync({ type: 'blob' });
  saveAs(blob, 'rebuslop - bilder og videoer.zip');
};


async function resetTask(taskId) {
  const taskRef = doc(db, "tasks", taskId);
  await updateDoc(taskRef, { status: "FREE", teamName: null, teamId: null });
}

export { takeTask, createTeam, updateTeamName, completeTask, resetTask, failTask, uploadMedia, likeFeedEntry };
