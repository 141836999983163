import { collection, onSnapshot, query } from "firebase/firestore";
import { useEffect, useState} from "react";
import Task from "./Task";
import { db } from "../firebase";
import {BekkUser, task, team} from "../utils/types";
import styled from "styled-components";
import { Heading } from "./Typography";
import { List } from "./List";

const Empty = styled.p`
  font-size: 1.2rem;
`;

export enum Filter {
  FREE,
  TAKEN,
  COMPLETED
}

interface TasksProps {
  teams: team[];
  user: BekkUser;

}

const Tasks = ({ teams, user }: TasksProps) => {
  const [tasks, setTasks] = useState<task[]>([]);
  const [teamHasTaskInProgress, setTeamHasTaskInProgress] = useState<boolean | null>(null)

  // Check if team has task in progress (can't take other tasks then)
  useEffect(() => {
    if (user !== null && user.teamId !== null) {
      const inProgress = tasks.some((t) => t.takenBy?.includes(user.teamId ?? "doesnotexist"))
      setTeamHasTaskInProgress(inProgress)
    }
  }, [user, tasks])

  // Retrieve all tasks
  useEffect(() => {
    const unsubscribeToTaskList = onSnapshot(query(collection(db, "tasks")), (querySnapshot) => {
      let newTasks: task[] = [];
      querySnapshot.forEach((doc) => {
        newTasks.push({
          taskId: doc.id,
          title: doc.data().title,
          description: doc.data().description,
          value: doc.data().value,
          takenBy: doc.data().takenBy ?? [],
          completedBy: doc.data().completedBy ?? [],
        });
      });
      setTasks(newTasks);
    });
    return () => unsubscribeToTaskList()
  }, []);

  const getTeamsWhoCompletedTask = (task: task): string[] => {
    if (task.completedBy.length === 0) return []

    let teamNames: string[] = []

    task.completedBy?.forEach((teamId) => {
      const teamName: string | undefined = teams.find((t) => t.id === teamId)?.name
      if (teamName !== undefined) teamNames.push(teamName)
    });

    return teamNames
  }

  const getTeamsWhoTakenTask = (task: task): string[] => {
    if (task.takenBy.length === 0) return []

    let teamNames: string[] = []

    task.takenBy?.forEach((teamId) => {
      const teamName: string | undefined = teams.find((t) => t.id === teamId)?.name
      if (teamName !== undefined) teamNames.push(teamName)
    });

    return teamNames
  }

  const completedByUsersTeam = (task: task) => {
    const completed = (user !== null && user?.teamId !== null && task.completedBy?.includes(user?.teamId)) ?? false
    return completed ? 1 : -1
  }

  const TasksWithHeading = ({ filteredTasks, heading, filter }: { filteredTasks: task[]; heading: string; filter: Filter; }) => {

    return (
      <>
        <Heading>{heading}</Heading>
        {filteredTasks.length > 0 ? (
          <List>
            {filteredTasks
              .sort((a, b) => b.value - a.value)
              .sort((a, b) => completedByUsersTeam(a) - completedByUsersTeam(b))
              .map((task) => {
                let teamNames: string[] = []

                if (filter === Filter.TAKEN) teamNames = getTeamsWhoTakenTask(task)
                else if (filter === Filter.COMPLETED) teamNames = getTeamsWhoCompletedTask(task)

                const userHasCompletedTask = completedByUsersTeam(task) === 1
                const userHasTakenTask = (user != null && user?.teamId !== null && task.takenBy?.includes(user?.teamId)) ?? false

                if ((userHasTakenTask || userHasCompletedTask) && filter === Filter.FREE) return null

                return (
                  <Task
                    key={heading + task.taskId}
                    task={task}
                    user={user}
                    filter={filter}
                    completedByTeamNames={teamNames}
                    takenByUser={userHasTakenTask}
                    completedByUser={userHasCompletedTask}
                    teamHasTaskInProgress={teamHasTaskInProgress}
                  />
                )})}
          </List>
        ) : (
          <Empty>Det er ingen oppgaver som er {heading.toLowerCase()}.</Empty>
        )}
      </>
    );
  };

  return (
    <>
      <TasksWithHeading
        filter={Filter.FREE}
        filteredTasks={tasks}
        heading="Tilgjengelig"
      />
      <TasksWithHeading
        heading="Tatt"
        filter={Filter.TAKEN}
        filteredTasks={tasks.filter((task)=> !!task.takenBy.length)}
      />
      <TasksWithHeading
        heading="Fullført"
        filter={Filter.COMPLETED}
        filteredTasks={tasks.filter((task) => !!task.completedBy.length)}
      />
    </>
  );
};

export default Tasks;
