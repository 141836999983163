import { team } from "../utils/types";
import styled from "styled-components";
import { Card } from "./Card";

const Container = styled(Card)<{ isUsersTeam: boolean }>`
  position: relative;
  display: flex;
  align-items: center;
  gap: 0.5rem;
  padding: 0.5rem;
  background-color: ${(props) => (props.isUsersTeam ? "var(--grønn)" : "var(--hvit)")};
`;

const Placement = styled.span`
  position: relative;
  width: 2rem;
  height: 2rem;

  display: flex;
  align-items: center;
  justify-content: center;
  line-height: 1;

  font-family: var(--din-medium);
  font-size: 1.5rem;
`;

const Points = styled.span`
  position: absolute;
  right: 0;

  display: flex;
  align-items: center;
  justify-content: center;

  height: 100%;
  min-width: calc(1rem + 3ch);
  color: var(--hvit);
  background: var(--solnedgang);
  font-family: var(--din-medium);
`;

const Name = styled.span``;

interface TeamProps {
    team: team;
    placement: number;
    isUsersTeam: boolean;
}

const Team = ({ team, placement, isUsersTeam }: TeamProps) => {
  return (
    <Container isUsersTeam={isUsersTeam}>
      <Placement>{placement}</Placement>
      <Name>{team.name}</Name>
      {isUsersTeam && (
        <span>⭐</span>
      )}
      <Points>{team.points}</Points>
    </Container>
  );
};

export default Team;
