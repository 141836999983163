import React, {useEffect, useState} from "react";
import styled from "styled-components";
import {BrowserRouter, Route, Routes} from "react-router-dom";
import Header from "./components/Header";
import Navigation from "./components/Navigation";
import Tasks from "./components/Tasks";
import Leaderboards from "./components/Leaderboards";
import routes from "./utils/routes";
import Feed from "./components/Feed";
import {db} from "./firebase";
import {BekkUser, FeatureFlags, team} from "./utils/types";
import {collection, onSnapshot, query} from "firebase/firestore";
import {authenticate, getEmployeeId, getEmployeeName, isAuthenticated, hashIsPresent} from "./utils/auth";
import {getConfig, setConfig} from "./utils/config";
import TeamOverview from "./components/TeamOverview";
import LoadingScreen from "./components/LoadingScreen";
import AdminOverview from "./components/AdminOverview";
import utf8 from "utf8";

const Container = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 100%;
  max-width: 1080px;
  margin: 0 auto;
  padding-bottom: 5rem;
`;

const Content = styled.main`
  position: relative;
  padding: 2rem 1rem 3rem;
  margin-bottom: auto;
`;

const App = () => {
  const [teams, setTeams] = useState<team[]>([]);
  const [authenticated, setAuthenticated] = useState<boolean>(false);
  const [bekkUser, setBekkUser] = useState<BekkUser>(null);
  const [isAdmin, setIsAdmin] = useState<boolean | null>(null);
  const [featureFlags, setFeatureFlags] = useState<FeatureFlags>(null);

  useEffect(() => {
    if (authenticated && bekkUser === null && teams.length > 0) {
      const employeeId = getEmployeeId();
      if (!!employeeId) {
        const teamId = teams.find(team => team.memberIds.includes(`${employeeId}`))?.id ?? null
        setBekkUser({
          name: utf8.decode(getEmployeeName() ?? ""),
          employeeId: employeeId,
          teamId: teamId
        })
      }
    }
  }, [authenticated, teams, bekkUser]);

  useEffect(() => {
    const unsubscribeToTeamList = onSnapshot(query(collection(db, "teams")), (querySnapshot) => {
      let newTeams: team[] = [];
      querySnapshot.forEach((doc) => {
        newTeams.push({
          id: doc.id,
          name: doc.data().name,
          points: doc.data().points,
          memberIds: doc.data().memberIds,
          memberNames: doc.data().memberNames ?? []
        });
      });
      setTeams(newTeams);
    });

    return () => unsubscribeToTeamList()
  }, []);

  // Get users admin status if logged in
  useEffect(() => {
    if (bekkUser !== null && isAdmin === null) {
      const unsubscribeToAdminList = onSnapshot(query(collection(db, "admin")), (querySnapshot) => {
        querySnapshot.forEach((doc) => {
          if (doc.id === "admins") {
            if (doc.data().employeeIds?.includes(`${bekkUser.employeeId}`)) setIsAdmin(true)
            else setIsAdmin(false)
          }
        });
      });
    }
  }, [bekkUser, isAdmin]);

  // Get feature flag status
  useEffect(() => {
    if (featureFlags === null) {
      const unsubscribeToAdminList = onSnapshot(query(collection(db, "admin")), (querySnapshot) => {
        querySnapshot.forEach((doc) => {
          if (doc.id === "featureFlags") {
            setFeatureFlags({
              enableHeartsInFeed: doc.data().enableHeartsInFeed ?? false,
              enableChangingTeamName: doc.data().enableChangingTeamName ?? false,
              messageForAll: doc.data().messageForAll ?? null
            })
          }
        });
      });
    }
  }, [featureFlags]);

  useEffect(() => {
    if (isAuthenticated()) {
      setAuthenticated(true);
    }
    if (hashIsPresent()) {
      authenticate();
    }
  }, []);

  const logInBekkUser = () => {
    setConfig(getConfig());
    if (!isAuthenticated()) {
      setAuthenticated(false)
      authenticate();
    } else {
      setAuthenticated(true)
      console.log('Authenticated!');
    }
  }

  const usersTeam: team | null = teams.find((team) => team.id === bekkUser?.teamId) ?? null
  const showTeamOverview = usersTeam !== null && bekkUser !== null

  return (
    <Container>
      <BrowserRouter>
        <Header loggedIn={authenticated} logIn={logInBekkUser} userMissingTeam={bekkUser?.teamId === null} userIsAdmin={!!isAdmin} messageForAll={featureFlags?.messageForAll ?? null}/>
        <LoadingScreen isLoading={teams.length === 0}>
          <Content>
            <Routes>
              <Route element={<Leaderboards teams={teams} usersTeamId={usersTeam?.id ?? null}/>} path={routes.leaderboards} />
              <Route element={<Feed user={bekkUser} teams={teams} enableLikes={!!featureFlags?.enableHeartsInFeed} isAdmin={!!isAdmin}/>} path={routes.feed} />
              <Route element={<Tasks user={bekkUser} teams={teams} />} index />
              {showTeamOverview && (
                <Route element={<TeamOverview team={usersTeam} enableChangingTeamName={!!featureFlags?.enableChangingTeamName}/>} path={routes.team}/>
              )}
              {!!isAdmin && (
                  <Route element={<AdminOverview teams={teams}/>} path={routes.admin}/>
              )}
            </Routes>
          </Content>
        </LoadingScreen>
        <Navigation showTeamOverview={showTeamOverview} showAdminOverview={!!isAdmin}/>
      </BrowserRouter>
    </Container>
  );
};

export default App;
