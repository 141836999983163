import styled from "styled-components";

export const Heading = styled.h2`
  font-size: 2rem;
  font-family: var(--din-medium);
  margin-bottom: 0.5rem;

  &:not(:first-child) {
    margin-top: 3.5rem;
  }
`;
