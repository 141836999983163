import {BekkUser, task} from "../utils/types";
import styled from "styled-components";
import {completeTask, failTask, takeTask} from "../firebase";
import {PrimaryButton, SecondaryButton} from "./Button";
import {Card} from "./Card";
import React from "react";
import {Filter} from "./Tasks";

const Container = styled(Card)<{completed: boolean}>`
  line-height: 1.25;
  font-size: 1.1rem;
  background-color: ${(props) => (props.completed ? "#dcf5f0" : "var(--hvit)")};
`;

const Content = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  padding: 1rem;
`;

const Title = styled.h3`
  font-size: 1.25rem;
  font-family: var(--din-medium);
`;

const Description = styled.p`
  max-width: 80ch;
  font-size: 1.1rem;
`;

const Points = styled.span`
  position: absolute;
  top: 0;
  right: 0;

  display: flex;
  align-items: center;
  justify-content: center;
  line-height: 1;
  padding: 0.5rem;
  min-width: calc(2ch + 1rem);

  color: var(--hvit);
  background: var(--grønn-kontrast);
  font-family: var(--din-medium);
`;

const Divider = styled.hr`
  border: 1px solid var(--overskyet);
  margin: 0.5rem 0;
`;

const Buttons = styled.div`
  display: flex;
`;

interface TaskProps {
  task: task;
  user: BekkUser;
  completedByTeamNames: string[];
  takenByUser: boolean;
  completedByUser: boolean;
  teamHasTaskInProgress: boolean | null;
  filter: Filter;
}

const Task = ({task, user, completedByTeamNames, takenByUser, completedByUser, teamHasTaskInProgress, filter}: TaskProps) => {

  const userIsLoggedIn = user !== null

  const handleTakeTask = async () => {
    await takeTask(task.taskId, user?.teamId);
  };

  const handleCompleteTask = async () => {
    await completeTask(task.taskId, task.value, user?.teamId);
  };

  const handleFailTask = async () => {
    await failTask(task.taskId, task.value, user?.teamId);
  };

  const userHasTeam = userIsLoggedIn && user?.teamId !== null
  const userCanTakeTask = filter === Filter.FREE && userHasTeam && !teamHasTaskInProgress && !takenByUser && !completedByUser
  const userCanCompleteTask = filter === Filter.TAKEN && userHasTeam && takenByUser && !completedByUser

  return (
    <Container completed={filter === Filter.COMPLETED && completedByUser}>
      <Content>
        <Title>{task.title}</Title>
        <Description>{task.description}</Description>
        <Points>{task.value}</Points>

        {!!completedByTeamNames.length && (
          <>
            <Divider />
            {completedByUser ?
              (<p>✅ Fullført av {completedByTeamNames.join(", ")}</p>)
              :
              (<p>{filter === Filter.COMPLETED ? "Fullført" : "Tatt"} av {completedByTeamNames.join(", ")}</p>)
            }
          </>
        )}
      </Content>
      {userCanTakeTask && (
        <PrimaryButton
          onClick={handleTakeTask}
          disabled={takenByUser}
        >
          Ta oppgaven!
        </PrimaryButton>
      )}
      {userCanCompleteTask && (
        <Buttons>
          <PrimaryButton onClick={handleCompleteTask}>
            Marker som ferdig
          </PrimaryButton>
          <SecondaryButton onClick={handleFailTask}>
            Avbryt oppgave (minuspoeng)
          </SecondaryButton>
        </Buttons>
      )}
    </Container>
  );
};

export default Task;
