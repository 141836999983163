import { collection, onSnapshot, query } from "firebase/firestore";
import { useEffect, useState } from "react";
import { db } from "../firebase";
import {BekkUser, media, team} from "../utils/types";
import FeedEntry from "./FeedEntry";
import { List } from "./List";
import { Heading } from "./Typography";
import UploadMedia from "./UploadMedia";

interface FeedProps {
  user: BekkUser;
  teams: team[];
  enableLikes: boolean;
  isAdmin: boolean;
}

const Feed = ({ user, teams, enableLikes, isAdmin }: FeedProps) => {
  const [mediaEntries, setMediaEntries] = useState<media[]>([]);

  useEffect(() => {
    const unsubscribeToImages = onSnapshot(query(collection(db, "media")), (querySnapshot) => {
      let newMediaEntries: media[] = [];
      querySnapshot.forEach((doc) => {
        const newEntry: media = {
          id: doc.id,
          url: doc.data().url,
          teamId: doc.data().teamId,
          date: doc.data().date,
          description: doc.data().description,
          name: doc.data().name,
          type: doc.data().type ?? "MEDIA",
          likeCount: doc.data().likeCount ?? 0
        };
        newMediaEntries.push(newEntry);
      });
      setMediaEntries(newMediaEntries);
    });
  }, []);

  return (
    <List>
      <Heading>Feed</Heading>
      <UploadMedia user={user} />
      {mediaEntries
        .sort((a, b) => b.date - a.date)
        .map((entry) => {
          const teamName = teams.find(team => team.id === entry.teamId)?.name
          return <FeedEntry key={entry.date} entry={entry} teamName={teamName} isAdmin={isAdmin} enableLikes={enableLikes}/>
          })}
    </List>
  );
};

export default Feed;
