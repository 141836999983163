import { getAudience, getIssuerDomain, getScopes } from './config';

function parseHash(hash: string): any {
  // tslint:disable-next-line:no-any
  const params: any = {};
  const hashes = hash.replace('#', '').split('&');
  for (const h of hashes) {
    const param = h.split('=');
    params[param[0]] = param[1];
  }
  return params;
}

function saveToken(token: string): void {
  try {
    localStorage.setItem('userToken', token);
  } catch (e) {
    alert(
      'Fikk ikke hentet ut userToken fra localStorage. Om du bruker safari i privat mode skru av dette for at siden skal laste :)'
    );
  }
}

export function getIdToken(): string | undefined {
  try {
    return localStorage.getItem('userToken') || undefined;
  } catch (e) {
    alert(
      'Fikk ikke hentet ut userToken fra localStorage. Om du bruker safari i privat mode skru av dette for at siden skal laste :)'
    );
    return '';
  }
}

function base64ToUtf8(str: string): string {
  return decodeURIComponent(window.atob(str));
}

function getClaimsFromToken(): any | undefined {
  const jwt = getIdToken();
  if (jwt === undefined || jwt === 'undefined') {
    return undefined;
  }

  const splits = jwt.split('.');
  if (splits.length === 3) {
    const encoded = splits[1];
    const jsonString = base64ToUtf8(encoded);
    return JSON.parse(jsonString);
  }
}

export function getEmployeeId(): any {
  const claimsFromToken = getClaimsFromToken();

  return claimsFromToken['https://api.bekk.no/claims/employeeId'] ?? null;
}

export function getEmployeeName() {
  const claimsFromToken = getClaimsFromToken()

  return claimsFromToken['name'];
}

function getApplicationRoot(): string {
  return window.location.origin;
}

function getCurrentState(): string {
  const state = window.location.href.replace(getApplicationRoot(), '');
  return encodeURIComponent(state);
}

function getAuth0Url(): string {
  const state = getCurrentState();
  const encodedCallback = encodeURIComponent(getApplicationRoot());
  const encodedScopes = encodeURIComponent(getScopes());

  return `https://${getIssuerDomain()}/authorize?client_id=${getAudience()}&response_type=token&redirect_uri=${encodedCallback}&scope=${encodedScopes}&state=${state}`;
}

function redirectToAuth0(): void {
  const url = getAuth0Url();
  window.location.replace(url);
}

function getStateFromHash(): string {
  const parameters = parseHash(window.location.hash);
  return decodeURIComponent(parameters.state);
}

function redirectToState(): void {
  const state = getStateFromHash();
  if (state === 'undefined') {
    window.location.replace(getApplicationRoot());
  } else {
    window.location.replace(state);
  }
}

export function hashIsPresent(): boolean {
  return !!window.location.hash;
}

function tryParseToken(): string | null {
  if (hashIsPresent()) {
    const parameters = parseHash(window.location.hash);
    return parameters.id_token;
  }
  return null;
}

function isExpired(): boolean {
  const claims = getClaimsFromToken();
  const epochNow = new Date().getTime() / 1000;
  return claims.exp <= epochNow - 10;
}

export function isAuthenticated(): boolean {
  const userToken = getIdToken();
  if (userToken) {
    return !isExpired();
  }
  return false;
}

export function authenticate(): void {
  const token = tryParseToken();
  if (token) {
    saveToken(token);
    redirectToState();
  } else {
    redirectToAuth0();
  }
}
