import styled from "styled-components";


const Container = styled.svg`
  width: 100%;
  height: 100%;
  fill: var(--sort);
  display: block;
`;

interface LoadingScreenProps {
    isLoading: boolean;
    children: JSX.Element;
}

const LoadingScreen = ({isLoading, children}: LoadingScreenProps) => {

  if (isLoading) {
    return (
      <Container viewBox="0 0 100 100">
        <circle fill="#000" stroke="none" cx="14" cy="50" r="6">
          <animate
            attributeName="opacity"
            dur="1s"
            values="0;1;0"
            repeatCount="indefinite"
            begin="0.1"/>
        </circle>
        <circle fill="#000" stroke="none" cx="39" cy="50" r="6">
          <animate
            attributeName="opacity"
            dur="1s"
            values="0;1;0"
            repeatCount="indefinite"
            begin="0.2"
          />
        </circle>
        <circle fill="#000" stroke="none" cx="62" cy="50" r="6">
          <animate
            attributeName="opacity"
            dur="1s"
            values="0;1;0"
            repeatCount="indefinite"
            begin="0.3"
          />
        </circle>
        <circle fill="#000" stroke="none" cx="86" cy="50" r="6">
          <animate
            attributeName="opacity"
            dur="1s"
            values="0;1;0"
            repeatCount="indefinite"
            begin="0.4"
          />
        </circle>
      </Container>
    );
  } else {
    return children;
  }
}

export default LoadingScreen;
