import {team} from "../utils/types";
import styled from "styled-components";
import {Heading} from "./Typography";
import {useState} from "react";
import {updateTeamName} from "../firebase";
import {PrimaryButton} from "./Button";

const Container = styled.div`
  display: flex;
  align-items: center;
  padding: 0.5rem;
  flex-direction: column;
`;

const Points = styled.h4`
  margin-bottom: 2rem;
`;

const TeamMembersHeading = styled.h4`
  margin-bottom: 0.5rem;
  font-family: var(--din-medium);
`;


const ChangeNameContainer = styled.div`
  display: flex;
  height: 100%;
  margin-top: 3rem;
  justify-content: center;
`;

const Input = styled.input`
  margin: 0 0 .5rem;
  padding: 0.5rem;
  width: 100%;
`;

interface TeamOverviewProps {
  team: team;
  enableChangingTeamName: boolean;
}

const TeamOverview = ({ team, enableChangingTeamName }: TeamOverviewProps) => {
  const [newTeamName, setNewTeamName] = useState<string>("");

  const changeTeamName = async (newTeamName: string, teamId: string | undefined) => {
    if (teamId === undefined || newTeamName.length === 0) return null;
    await updateTeamName(newTeamName, teamId)
    setNewTeamName("")
  }

  return (
    <>
      <Container>
        <Heading>{team.name}</Heading>
        <Points>⭐ {team.points} poeng ⭐</Points>
        <TeamMembersHeading>Lagmedlemmer:</TeamMembersHeading>
        {team.memberNames.map((name) => (
          <p key={name}>{name}</p>
        ))}
      </Container>
      {enableChangingTeamName && (
        <ChangeNameContainer>
          <div>
            <Input
              type="text"
              placeholder="Nytt lagnavn"
              value={newTeamName}
              onChange={(e) => setNewTeamName(e.target.value)}
            />
            <PrimaryButton onClick={() => changeTeamName(newTeamName, team.id)}>Bytt lagnavn</PrimaryButton>
          </div>
       </ChangeNameContainer>
      )}
    </>
  );
};

export default TeamOverview;
