import styled from "styled-components";

const Button = styled.button`
  width: 100%;
  border: none;
  background: none;
  padding: 0.5rem 1rem;
  cursor: pointer;
`;

export const PrimaryButton = styled(Button)`
  color: var(--hvit);
  background: var(--sort);
  :disabled {
    background: #6a6a6a;
    cursor: not-allowed;
  }
`;

export const SecondaryButton = styled(Button)`
  border: 2px solid var(--sort);
`;
