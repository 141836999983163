import React from "react";
import { createRoot } from "react-dom/client";
import App from "./App";
import * as serviceWorkerRegistration from "./serviceWorkerRegistration";

import { createGlobalStyle } from "styled-components";

const GlobalStyle = createGlobalStyle`
  :root {
    --hvit: #ffffff;
    --sort: #0e0e0e;
    --overskyet: #e7e7e7;
    --overskyet-kontrast: #cecece;
    --soloppgang: #ffb88d;
    --soloppgang-kontrast: #ff8034;
    --skyfritt: #b1e8ff;
    --skyfritt-kontrast: #43cbff;
    --solnedgang: #ff9999;
    --solnedgang-kontrast: #ff5b5b;
    --kveld: #e5b1ff;
    --kveld-kontrast: #8e24c9;
    --natt: #6d7abb;
    --natt-kontrast: #162365;
    --regn: #bccedd;
    --regn-kontrast: #7e9cb9;
    --sol: #fff2ad;
    --sol-special: #fff8d6;
    --sol-kontrast: #fff02b;
    --grønn: #a1f5e3;
    --grønn-kontrast: #16dbc4;
    --morgendis: #707070;
    --din-light: DINOT-Light, 'Calibre Light', sans-serif;
    --din-light-italic: DINOT-Light-Italic, 'Calibre Light', sans-serif;
    --din-regular: DINOT, 'Calibre Light', sans-serif;
    --din-medium: DINOT-Medium, 'Calibre Light', sans-serif;
    --newzald-book: Newzald-Book, Georgia, serif;
  }

  *, *::before, *::after {
    box-sizing: border-box;
    margin: 0;
  }
  html, body, #root {
    height: 100%;
  }
  body {
    font-size: 1.25rem;
    font-family: var(--din-regular);
    font-synthesis: none;
    -webkit-font-smoothing: antialiased;
    color: var(--sort);
    text-underline-offset: 0.1em;
    background: #f8f8f8
  }
  img, picture, video, canvas, svg {
    display: block;
    max-width: 100%;
  }
  input, button, textarea, select {
    font: inherit;
  }
  #root, #__next {
    isolation: isolate;
  }
`;

const container = document.getElementById('root');
const root = createRoot(container!);
root.render(
  <React.StrictMode>
    <GlobalStyle/>
    <App/>
  </React.StrictMode>
);

serviceWorkerRegistration.register();
