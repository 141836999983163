import styled from "styled-components";
import { media } from "../utils/types";
import {likeFeedEntry} from "../firebase";

const Info = styled.div`
  display: flex;
  justify-content: space-between;
  color: var(--morgendis);
  padding: 1rem;
`;

const Image = styled.img`
  width: 100%;
`;

const Video = styled.video`
  width: 100%;
`;

const DescriptionContainer = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 1rem;
`;

const Description = styled.p`
  padding: 1rem;
`;

const HeartCount = styled.p< {isClickable: boolean}>`
  font-size: 2rem;
  cursor: ${(props) => (props.isClickable ? "pointer" : "inherit")};
  overflow-wrap: anywhere;
`;

const MediaCard = styled.div`
  background: var(--hvit);
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
`;

const TextCard = styled.div`
  background: var(--hvit);
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
`;

const formatDate = (date: number) => {
  return new Date(date).toLocaleTimeString("nb-NO", { hour: "2-digit", minute: "2-digit", second: "2-digit" })
}

const getFeedBackground = (type: string) => {
  switch (type) {
    case "CLAIMED":
      return "var(--soloppgang)";
    case "COMPLETED":
      return "var(--grønn)";
    case "RETRACTED":
      return "var(--solnedgang)";
    default:
      return "white";
  }
}

const fileIsVideoFormat = (mediaUrl: string): boolean => {
  const videoFormats = [".mov", ".mp4", ".avi"]
  return videoFormats.some((format) => mediaUrl.toLocaleLowerCase().includes(format))
}

const nthHeart = (numberOfHearts: number): string => {
  let hearts = ""
  for (let i = 0; i !== numberOfHearts; i++) {
    hearts += "♥️"
  }
  return hearts;
}

interface FeedEntryProps {
  entry: media;
  teamName?: string;
  isAdmin: boolean;
  enableLikes: boolean;
}

const FeedEntry = ({ entry, teamName, enableLikes, isAdmin = false, }: FeedEntryProps) => {

  const addLike = async () => {
    if (isAdmin) await likeFeedEntry(entry.id)
  };

  if (entry.type === "MEDIA"){
    const isMovie: boolean = entry?.url !== null && entry?.url !== undefined && fileIsVideoFormat(entry.url)

    return (
      <MediaCard>
        <Info>
          {entry?.name !== null ? (
            <p>{entry.name} {!!teamName ? `(${teamName})` : isAdmin ? "(bootcamp coach)" : "(ikke tildelt lag)"}</p>
          ) : (
            <p>Ikke-innlogget bruker</p>
          )}
          <p>{formatDate(entry.date)}</p>
        </Info>
        {!isMovie && <Image src={entry.url} alt="bilde her" />}
        {isMovie && (
          <Video controls>
            <source src={entry.url} type="video/mp4" />
          </Video>
        )}
        <DescriptionContainer>
          <Description>{entry.description ?? ""}</Description>
          {enableLikes && (
              <HeartCount isClickable={isAdmin} onClick={addLike}>{entry.likeCount === 0 ? "🤍" : nthHeart(entry.likeCount)}</HeartCount>
          )}
        </DescriptionContainer>
      </MediaCard>
    )
  } else {
    return (
      <TextCard style={{backgroundColor: getFeedBackground(entry.type)}}>
          <Info style={{color: "black"}}>
            <p>{`${teamName} ${entry.description}`}</p>
            <p>{formatDate(entry.date)}</p>
          </Info>
      </TextCard>
    )
  }
};

export default FeedEntry;
