import React from "react";
import styled from "styled-components";
import Bekk from "./Bekk";

const Wrapper = styled.div``;

const Container = styled.nav`
  display: flex;
  align-items: baseline;
  justify-content: space-between;
  padding: 1rem;
  background: var(--sort);
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
`;

const Title = styled.h1`
  font-size: 1.25rem;
  color: var(--hvit);
  font-family: var(--din-medium);
`;

const Login = styled.div<{clickable: boolean}>`
  display: block;
  text-align: center;
  background: var(--solnedgang);
  color: var(--hvit);
  padding: 1rem;
  width: 100%;
  cursor: ${(props) => (props.clickable ? 'pointer' : 'inherit')};
`;

interface HeaderProps {
    logIn: () => void;
    loggedIn: boolean;
    userMissingTeam: boolean;
    userIsAdmin: boolean;
    messageForAll: string | null;
}

const Header = ({loggedIn, logIn, userMissingTeam, userIsAdmin = false, messageForAll}: HeaderProps) => (
  <Wrapper>
    <Container>
      <Bekk />
      <Title>Rebusløp</Title>
    </Container>
    {!loggedIn && <Login clickable onClick={logIn}>Logg inn for å ta oppgaver</Login>}
    {loggedIn && userMissingTeam && !userIsAdmin && <Login clickable={false}>Du er ikke tildelt et team!</Login>}
    {loggedIn && userIsAdmin && <Login clickable={false}>Admin {!userMissingTeam ? "og teammedlem" : ""}</Login>}
    {!!messageForAll && <Login clickable={false}>{messageForAll}</Login>}
  </Wrapper>
);

export default Header;
