import React from "react";
import { NavLink } from "react-router-dom";
import styled from "styled-components";
import routes from "../utils/routes";
import {FeedIcon, LeaderboardsIcon, TasksIcon, TeamIcon} from "./Icons";

const Container = styled.nav`
  position: fixed;
  bottom: 0;
  right: 0;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-around;
  background: var(--sort);
`;

const Link = styled(NavLink)`
  display: flex;
  flex-direction: column;
  align-items: center;
  text-decoration: none;
  padding: 1rem 0.5rem;
  color: var(--morgendis);
  fill: var(--morgendis);
  line-height: 1;
  margin-top: -0.5rem;

  &.active {
    color: var(--hvit);
    fill: var(--hvit);
    font-family: var(--din-medium);
  }
`;

interface NaviationProps {
    showTeamOverview: boolean;
    showAdminOverview: boolean;
}

const Navigation = ({showTeamOverview, showAdminOverview}: NaviationProps) => (
  <Container>
    <Link to={routes.leaderboards}>
      <LeaderboardsIcon />
      Ledertavle
    </Link>
    <Link to={routes.feed}>
      <FeedIcon />
      Feed
    </Link>
    <Link to={routes.tasks}>
      <TasksIcon />
      Oppgaver
    </Link>
    {showTeamOverview && !showAdminOverview && (
      <Link to={routes.team}>
        <TeamIcon/>
        Team
      </Link>
    )}
    {showAdminOverview && (
      <Link to={routes.admin}>
        <TeamIcon/>
        Admin
      </Link>
    )}
  </Container>
);

export default Navigation;
