let issuerDomain: string;
let audience: string;
let scopes: string;
let timekeeperSvcUrl: string;
let employeeSvcUrl: string;
let staffingSvcUrl: string;
let calendarSvcUrl: string;
let environment: string;

export interface IConfig {
  audience: string;
  issuerDomain: string;
  scopes: string;
  timekeeperSvcUrl: string;
  employeeSvcUrl: string;
  staffingSvcUrl: string;
  calendarSvcUrl: string;
  environment: string;
}

export function setConfig(config: IConfig): void {
  issuerDomain = config.issuerDomain;
  audience = config.audience;
  scopes = config.scopes;
  timekeeperSvcUrl = config.timekeeperSvcUrl;
  employeeSvcUrl = config.employeeSvcUrl;
  staffingSvcUrl = config.staffingSvcUrl;
  calendarSvcUrl = config.calendarSvcUrl;
  environment = config.environment;
}

export function getConfig(): IConfig {
  const devConfig: IConfig =
  {
    "audience": "QHQy75S7tmnhDdBGYSnszzlhMPul0fAE",
    "issuerDomain": "bekk-dev.eu.auth0.com",
    "scopes": "openid name groups",
    "timekeeperSvcUrl": " https://api.dev.bekk.no/timekeeper-svc",
    "employeeSvcUrl": "https://api.dev.bekk.no/employee-svc",
    "staffingSvcUrl": "https://api.dev.bekk.no/teamstaffing-svc",
    "calendarSvcUrl": "https://api.dev.bekk.no/calendar-svc",
    "environment": "development"
  };

  const prodConfig: IConfig =
  {
    "audience":"HuH7oGHSgymn4mYLzEClyE2bhQSM1iTC",
    "issuerDomain":"bekk.eu.auth0.com",
    "scopes":"openid name groups",
    "timekeeperSvcUrl":"https://api.bekk.no/timekeeper-svc",
    "employeeSvcUrl":"https://api.bekk.no/employee-svc",
    "staffingSvcUrl":"https://api.bekk.no/teamstaffing-svc",
    "calendarSvcUrl":"https://api.bekk.no/calendar-svc",
    "environment": "production"
  };

  return prodConfig;
}

export function getIssuerDomain(): string {
  return issuerDomain;
}

export function getAudience(): string {
  return audience;
}

export function getScopes(): string {
  return scopes;
}

export function getEnvironment() {
  return environment;
}
