import styled from "styled-components";

const Icon = styled.svg`
  width: 2rem;
  fill: inherit;
`;

export const LeaderboardsIcon = () => (
  <Icon xmlns="http://www.w3.org/2000/svg" viewBox="0 0 752 752">
    <path d="m218.61 345.14h83.395c2.2891-0.019531 4.4883 0.89062 6.0938 2.5234 1.6055 1.6328 2.4766 3.8477 2.418 6.1328v198.83c-0.019531 2.2383-0.92969 4.375-2.5234 5.9414-1.5977 1.5703-3.75 2.4414-5.9883 2.418h-83.395c-4.6016-0.039063-8.3203-3.7578-8.3594-8.3594v-198.83c-0.0625-2.2617 0.78906-4.4531 2.3633-6.082 1.5703-1.6289 3.7344-2.5547 5.9961-2.5742zm107.3-7.3984v-138.38c0-2.2188 0.87891-4.3438 2.4492-5.9141 1.5664-1.5664 3.6953-2.4492 5.9102-2.4492h83.469c4.6211 0 8.3633 3.7461 8.3633 8.3633v353.26c0 2.2188-0.88281 4.3438-2.4492 5.9102-1.5664 1.5703-3.6953 2.4492-5.9141 2.4492h-83.469c-4.6172 0-8.3594-3.7422-8.3594-8.3594zm207.49 223.25h-83.395c-4.6016-0.039063-8.3242-3.7578-8.3633-8.3594v-276.16c0-2.2188 0.88281-4.3438 2.4492-5.9141 1.5664-1.5664 3.6953-2.4492 5.9141-2.4492h83.395c4.6172 0 8.3594 3.7422 8.3594 8.3633v276.16c-0.039062 4.6016-3.7578 8.3203-8.3594 8.3594z" />
  </Icon>
);

export const FeedIcon = () => (
  <Icon xmlns="http://www.w3.org/2000/svg" viewBox="0 0 752 752">
    <path d="m250.21 265.01c-20.719 0-37 16.281-37 37v170.19h-25.156c-14.801-0.74219-26.641-13.32-26.641-28.859v-253.07c0-15.539 11.84-28.121 26.641-28.859h316.71c15.539 0.73828 27.379 13.32 26.641 28.859l-0.003907 74.738zm73.996 118.39c-8.1406 0-14.801 6.6602-14.801 14.801s6.6602 14.801 14.801 14.801 14.801-6.6602 14.801-14.801-6.6602-14.801-14.801-14.801zm266.39-81.398v215.33l-88.797-63.637c-8.1406-5.1797-18.5-5.1797-25.898 0.73828l-77.699 60.68-45.137-33.297c-8.1406-6.6602-19.238-6.6602-27.379 0l-97.676 71.773v-251.59c0-12.578 9.6211-22.199 22.199-22.199h318.19c12.578 0 22.199 9.6211 22.199 22.199zm-236.79 96.195c0-16.281-13.32-29.598-29.598-29.598-16.281 0-29.598 13.32-29.598 29.598 0 16.281 13.32 29.598 29.598 29.598 16.281 0.003906 29.598-13.316 29.598-29.598zm131.71 68.078-82.137 63.637c-0.73828 0.73828-0.73828 0.73828-1.4805 1.4805h-1.4805-1.4805c-0.73828 0-2.2188 0-2.9609-0.73828-0.73828 0-0.73828-0.73828-1.4805-0.73828l-50.316-37c-2.9609-2.2188-6.6602-2.2188-9.6211 0l-106.55 79.176c1.4805 11.098 11.098 18.5 22.199 18.5h318.19c12.578 0 22.199-9.6211 22.199-22.199v-33.297l-96.938-69.559c-2.9609-1.4805-5.918-1.4805-8.1406 0.73828z" />
  </Icon>
);

export const TasksIcon = () => (
  <Icon xmlns="http://www.w3.org/2000/svg" viewBox="0 0 752 752">
    <path d="m592.43 580.11-93.77-129.29c-5.2109-7.1055-16.102-7.1055-21.312 0l-26.992 36.938-61.566-84.77v-109.87c6.1562 1.8945 11.84 2.3672 17.523 2.3672 15.629 0 29.836-5.2109 43.57-10.418 16.574-6.1562 32.203-11.84 49.727-8.9961 7.1055 1.4219 13.262-4.2617 13.262-11.367l-0.003906-91.402c0-5.6836-3.7891-10.418-9.4727-11.367-23.68-4.2617-44.043 3.3164-62.039 9.9453-18.469 7.1055-34.098 13.262-52.094 5.2109v-11.84c0-7.1055-5.2109-13.262-11.84-13.734-8.0508-0.94531-14.68 5.2109-14.68 13.262v238.21l-61.566 84.77-26.992-36.938c-5.2109-7.1055-16.102-7.1055-21.312 0l-93.297 129.29c-6.1562 8.5234 0 20.836 10.891 20.836h411.54c10.422 0.003906 16.578-12.309 10.422-20.836z" />
  </Icon>
);

export const TeamIcon = () => (
  <Icon xmlns="http://www.w3.org/2000/svg" viewBox="0 0 752 752">
    <path d="m425.33 306.94c0-27.203-22.129-49.332-49.332-49.332s-49.332 22.129-49.332 49.332v19.734c0 27.203 22.129 49.332 49.332 49.332s49.332-22.129 49.332-49.332z"/>
    <path d="m277.34 484.53v39.465c0 5.457 4.4219 9.8672 9.8672 9.8672h177.59c5.4453 0 9.8672-4.4102 9.8672-9.8672v-39.465c0-48.965-39.84-88.797-88.797-88.797h-19.734c-48.957 0-88.797 39.828-88.797 88.797z"/>
    <path d="m257.61 336.54c27.203 0 49.332-22.129 49.332-49.332v-19.734c0-27.203-22.129-49.332-49.332-49.332s-49.332 22.129-49.332 49.332v19.734c0 27.203 22.133 49.332 49.332 49.332z"/>
    <path d="m257.61 484.53c0-47.258 30.289-87.516 72.516-102.31-16.078-15.984-38.18-25.949-62.648-25.949h-19.734c-48.938 0-88.797 39.859-88.797 88.797v39.465c0 5.4258 4.4414 9.8672 9.8672 9.8672h88.797z"/>
    <path d="m494.4 336.54c27.203 0 49.332-22.129 49.332-49.332v-19.734c0-27.203-22.129-49.332-49.332-49.332-27.203 0-49.332 22.129-49.332 49.332v19.734c0 27.203 22.129 49.332 49.332 49.332z"/>
    <path d="m504.26 356.27h-19.734c-24.469 0-46.57 9.9648-62.652 25.949 42.23 14.797 72.523 55.051 72.523 102.31v9.8672h88.797c5.4258 0 9.8672-4.4414 9.8672-9.8672v-39.465c-0.003906-48.938-39.863-88.797-88.801-88.797z"/>
  </Icon>
);
