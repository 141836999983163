import styled from "styled-components";
import {team} from "../utils/types";
import {Heading} from "./Typography";
import {PrimaryButton} from "./Button";
import {downloadAllMediaAsZipFile} from "../firebase";
import {useState} from "react";
import LoadingIcon from "../images/LoadingIcon";

const Container = styled.div`
  display: flex;
  align-items: center;
  padding: 0.5rem;
  flex-direction: column;
`;

const Team = styled.div`
  width: 100%;
  display: inline-flex;
  align-items: start;
  flex-direction: column;
  margin-bottom: 3rem;
`;

const DownloadMediaContainer = styled.div`
  margin-top: 3rem;
`;

const DownloadMediaTitle = styled.h3`
  margin-bottom: 1rem;
  font-size: 1.5rem;
  font-family: var(--din-medium);
`;

const DownloadMediaButton = styled(PrimaryButton)`
    background: var(--solnedgang-kontrast)
`;

interface AdminOverviewProps {
  teams: team[];
}

const AdminOverview = ({teams}: AdminOverviewProps) => {
  const [isDownloadingAllMedia, setIsDownloadingAllMedia] = useState<boolean>(false);

  const downloadAllMedia = async () => {
    const confirmText = "Dette kan være mange GB med data og kan ta et par minutter avhengig av mengden filer! Ikke gjør dette på mobil! Er du sikker på at vil laste ned alle bilder og videoer?";
    // eslint-disable-next-line no-restricted-globals
    if (confirm(confirmText)) {
      setIsDownloadingAllMedia(true)
      await downloadAllMediaAsZipFile()
        .then(() => {
          setIsDownloadingAllMedia(false)
        })
    }
  }

  return (
    <Container>
      {teams.map((team) => (
        <Team key={team.id}>
          <Heading>{`${team.name} - ${team.points} poeng`}</Heading>
          {team.memberNames.map((teamMember) => (
              <p key={teamMember}>{teamMember}</p>
          ))}
        </Team>
      ))}
      <DownloadMediaContainer>
          <DownloadMediaTitle>⚠️ Last ned alle bilder og videoer som zip fil ⚠️</DownloadMediaTitle>
        <DownloadMediaButton onClick={downloadAllMedia}>{isDownloadingAllMedia ? <LoadingIcon /> : "Last ned zip"}</DownloadMediaButton>
      </DownloadMediaContainer>
    </Container>
);
}

export default AdminOverview;

