import React from "react";
import styled from "styled-components";

const Container = styled.svg`
  max-height: 22px;
  margin: 0 auto 0 auto;
  display:inline-block;
  fill: var(--hvit);
`;

const LoadingIcon = () => (
  <Container xmlns="http://www.w3.org/2000/svg" viewBox="0 20 80 50" height="100%" width="100%">
    <circle fill="#fff" stroke="none" cx="6" cy="50" r="6">
      <animateTransform
         attributeName="transform"
         dur="1s"
         type="translate"
         values="0 15 ; 0 -15; 0 15"
         repeatCount="indefinite"
         begin="0.1"/>
    </circle>
    <circle fill="#fff" stroke="none" cx="30" cy="50" r="6">
      <animateTransform
         attributeName="transform"
         dur="1s"
         type="translate"
         values="0 10 ; 0 -10; 0 10"
         repeatCount="indefinite"
         begin="0.2"/>
    </circle>
    <circle fill="#fff" stroke="none" cx="54" cy="50" r="6">
      <animateTransform
         attributeName="transform"
         dur="1s"
         type="translate"
         values="0 5 ; 0 -5; 0 5"
         repeatCount="indefinite"
         begin="0.3"/>
    </circle>
  </Container>
);

export default LoadingIcon;
