import { useState } from "react";
import {uploadMedia } from "../firebase";
import styled from "styled-components";
import { PrimaryButton } from "./Button";
import LoadingIcon from "../images/LoadingIcon";
import {BekkUser} from "../utils/types";

const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
`;

interface UploadMediaProps {
    user: BekkUser;
}

const UploadMedia = ({ user }: UploadMediaProps) => {
  const [loading, setLoading] = useState(false);
  const [description, setDescription] = useState<string>("");
  const [file, setFile] = useState<any>();

  return (
    <Container>
      <label>Velg fil å laste opp</label>
      <input
        id="media-upload-input"
        type="file"
        accept=".png, .jpg, .jpeg, .gif, video/*"
        onChange={async (event: any) => {
          if (!event.target.files) {
            return;
          }
          setFile(event.target.files[0]);
        }}
        disabled={loading}
      />
      {file && (
        <input
          type="text"
          placeholder="Beskrivelse"
          onChange={(e) => {
            setDescription(e.target.value);
          }}
          disabled={loading}
        />
      )}
      {file && (
        <PrimaryButton
          disabled={loading}
          onClick={async () => {
            setLoading(true);

            await uploadMedia(file, description, user?.teamId ?? null, user?.name ?? null, )

            setFile(null);

            setLoading(false);
          }}
        >
            {loading ? <LoadingIcon /> : "Last opp"}
        </PrimaryButton>
      )}
    </Container>
  );
};

export default UploadMedia;
