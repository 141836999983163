import Team from "./Team";
import { team } from "../utils/types";
import { Heading } from "./Typography";
import { List } from "./List";


interface LeaderboardsProps {
    usersTeamId: string | null;
    teams: team[];
}

const Leaderboards = ({ teams, usersTeamId }: LeaderboardsProps) => {

  return (
    <>
      <Heading>Ledertavle</Heading>
      <List>
        {[...teams]
          .sort((a, b) => b.points - a.points)
          .map((team, index) => {
            return <Team key={team.id} team={team} placement={index + 1} isUsersTeam={usersTeamId !== null && team.id === usersTeamId} />;
          })}
      </List>
    </>
  );
};

export default Leaderboards;
