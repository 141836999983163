const routes = {
  tasks: "/",
  feed: "/feed",
  leaderboards: "/leaderboards",
  login: "/login",
  team: "/team",
  admin: "/admin"
};

export default routes;
